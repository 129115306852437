<template>
  <div
    v-on:click="toggle_height_handler_one"
    class="flex bg-right_ justify-between pt-4 pl-5 pb-4 rightSideBarOptions bg-right_"
  >
    <div>
      <p class="sidebar-label lables_with_ico">
        <img src="../../../assets/img/genaral_settings.png" alt="" />
        General Settings
      </p>
    </div>
    <div class="d-flex self-center">
      <p v-if="!isExpandFeatureOne">
        <i class="dwnArrw fas fa-angle-down"></i>
      </p>
      <p v-else><i class="upArrw fas fa-angle-up"></i></p>
    </div>
  </div>

  <div
    v-bind:style="{ display: isExpandFeatureOne ? '' : 'none' }"
    class="expand-right-sidebar-feature-one"
  >
    <div>
      <div class="custom-radio-wrap mb-4">
        <div class="flex flex-col mt-3">
          <p class="label_heading">Display Name</p>
          <input
            :id="widgetId + '1'"
            type="text"
            class="fields border-0 block font-color"
            @input="changeTitleHandler($event)"
            :value="fullName"
          />
        </div>

        <div class="flex flex-col mt-3">
          <p class="label_heading">Mnemonic</p>
          <!-- <input type="text" v-model="searchMnemonic" placeholder="Search mnemonic..." class="fields border-0 block font-color"/> -->

          <!-- <select
            :id="widgetId + '2'"
            class="fields focus:ring-indigo-500 border-0 block left-0 font-color leading-none"
            @change="mnemonicChangeHandler($event)"
            :value="title"
          >
            <option>{{ title }}</option>
            <option :key="index" v-for="(tag, index) in tags" v-show="!searchMnemonic || (searchMnemonic && tag.toLowerCase().replace(/\s/g, '').includes(searchMnemonic.toLowerCase().replace(/\s/g, '')))">
              {{ tag }}
            </option>
          </select> -->
          <div class="dropdown_custom">
          <input v-model="searchMnemonic" type="text" id="searchInput" placeholder="Search..." @click="toggleDropdown" v-click-outside="handleClickOutside">
          <span class="dropdown-icon_custom">&#9662;</span>
          <div class="dropdown-content_custom" v-if="isDropdownVisible">
            <div :key="index" v-for="(tag, index) in tags" v-show="!searchMnemonic || (searchMnemonic && tag.toLowerCase().replace(/\s/g, '').includes(searchMnemonic.toLowerCase().replace(/\s/g, '')))" @click="mnemonicChangeHandler(tag)">{{ tag }}</div>

          </div>
        </div>
        </div>
        <!-- <div class="flex flex-col mt-3">
          <p class="label_heading">Unit</p>
          <input
            :id="widgetId + '3'"
            disabled
            type="text"
            class="fields border-0 block font-color"
            :value="unit"
          />
        </div> -->
        <!-- <UnitSelect  :displayId="displayId" :mnemonic="mnemonic"/> -->
        <div class="flex flex-col mt-3" v-if="category != 'Miscellaneous'">
          <p class="label_heading">Units</p>
          <select
            :id="widgetId + '2'"
            class="fields focus:ring-indigo-500 border-0 block left-0 font-color leading-none"
            v-model="selectedUnit"
            @change="unitChangeHandler"
          >
            <option :key="index" v-for="(tag, index) in units?.units">
              {{ tag }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helperServices from "../../../helper-services.js";
// import UnitSelect from "./UnitSelect.vue";
import { db } from "../../../db";
export default {
  name: "MnemonicSelect",
  props: {
    widgetId: String,
    displayId: String,
    mnemonic: String,
  },
  components: {
    // UnitSelect,
  },
  data: () => ({
    isExpandFeatureOne: true,
    Dtags: [],
    idx: -1,
    tags: [],
    dipUnits: [],
    units: null,
    selectedUnit: "",
    unitcategoriesList: [],
    defaultSelectUnit:"",
    category:"",
    searchMnemonic: '',
    isDropdownVisible:false
  }),
  computed: {
    unit() {
      return this.$store.state.rect.rects[this.idx]?.unit;
    },
    fullName() {
      return this.$store.state.rect.rects[this.idx]?.fullName || "";
    },
    title() {
      return this.$store.state.rect.rects[this.idx]?.title || "";
    },
  },
  mounted() {
    // document.addEventListener("click", this.handleClickOutside);
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
    this.getMnemonicsList();
    this.searchMnemonic=this.mnemonic;
  },
  methods: {
    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    changeTitleHandler(e) {
      const payload = {
        displayId: this.displayId,
        widgetId: this.widgetId,
        // value: e.target.value,
        fullName: e.target.value,
      };
      this.$emit("mnemonic_title", payload);
    },
    unitChangeHandler() {
      const payload = {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: this.title,
        unit: this.selectedUnit,
        fullName: this.fullName,
      };
      this.$emit("mnemonic_hange", payload);
    },
    async mnemonicChangeHandler(menmonic) {
      const payload = {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: this.Dtags[this.tags.indexOf(menmonic)],
        unit: this.dipUnits[this.tags.indexOf(menmonic)],
        fullName: menmonic,
      };
      this.selectedUnit = payload.unit;
      this.defaultSelectUnit = payload.unit;
      // if (this.units == null) {
        const mnemonicKey = this.Dtags.findIndex((t) => t == payload.value);
        const catregory = this.unitcategoriesList[mnemonicKey];
        this.category = catregory;
        this.units = await helperServices.getUnitCategories(catregory);
      // }
      this.searchMnemonic=this.mnemonic;
      this.$emit("mnemonic_hange", payload);
    },
    async getMnemonicsList() {
      const wellDet = this.$store.state.disp.displays[this.displayId];
      const logDetails = await helperServices.getIndexDetails(wellDet.wellId);
      this.tags = logDetails.data.logs[wellDet.logType].displayTags;
      this.Dtags = logDetails.data.logs[wellDet.logType].tags;
      this.dipUnits = logDetails.data.logs[wellDet.logType].units;
      this.unitcategoriesList =
        logDetails.data.logs[wellDet.logType].categories;
      if (this.mnemonic != "") {
        const mnemonicKey = this.Dtags.findIndex((t) => t == this.mnemonic);
        this.selectedUnit = this.dipUnits[mnemonicKey];
        this.defaultSelectUnit = this.dipUnits[mnemonicKey];
        const catregory = this.unitcategoriesList[mnemonicKey];
        this.category = catregory;
        this.units = await helperServices.getUnitCategories(catregory);
        if(this.unit)this.selectedUnit=this.unit;
      }
    },
    toggleDropdown() {
      this.isDropdownVisible = true;
    },
    handleClickOutside(event) {
      console.log("even outside click",event);
      this.isDropdownVisible = false;
     
    }
  },
  watch: {
    selectedUnit: {
      handler(newValue, oldValue) {
        if (oldValue != newValue) {
          if (this.units && this.units.units_category_id) {
            // console.log("selectedUnit watcheer:-------", oldValue, newValue,this.selectedUnit,this.defaultSelectUnit);
            if (this.selectedUnit == this.defaultSelectUnit) {
              let emptDet = {
                unit_conversion_factor_id: 7,
                units_category_id: 1,
                from_unit:this.defaultSelectUnit ,
                to_unit: this.defaultSelectUnit,
                conversion_factor: 1,
              };
              this.$emit("unit_conversion_factor_change", emptDet);
            } else {
            db.unitfactors
              .where("units_category_id")
              .equals(this.units.units_category_id)
              .each((fact) => {
                if (fact.from_unit == oldValue && fact.to_unit == newValue) {
                  this.$emit('unit_conversion_factor_change',fact);
                }
              });
            }
          }
        }
      },
    },
  },

};
</script>
<style scoped src="../../../assets/styles/propstyle.css">
    .dropdown_custom {
      position: relative;
      width: 200px;
    }
    .dropdown_custom input {
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
    }
    .dropdown-content_custom {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      border: 1px solid #ccc;
      background-color: white;
      display: none;
      z-index: 1;
    }
    .dropdown-content_custom div {
      padding: 8px;
      cursor: pointer;
    }
    .dropdown-content_custom div:hover {
      background-color: #f1f1f1;
    }
</style>
